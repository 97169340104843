import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Button from './Components/Button';

const Dashboard = () =>
{
    React.useEffect(() => {
        document.title = "Dashboard"
    })

    const [userData, setUserData] = useState(null);
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() =>
    {
        const accessToken = sessionStorage.getItem('accessToken');
        if (accessToken) {
            fetchUserData(accessToken);
        } else {
            // Redirect to login if there's no access token
            navigate('/');
        }
    }, [navigate]);

    const fetchUserData = (accessToken) =>
    {
        axios.get('https://discord.com/api/users/@me', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response =>
            {
                setUserData(response.data);
                const authorizedIds = process.env.REACT_APP_AUTHORIZED_IDS.split(',');
                // Check if the user's ID is in the authorized IDs array
                if (!authorizedIds.includes(response.data.id)) {
                    // Redirect to login page if IDs do not match
                    navigate('/');
                }
            })
            .catch(error =>
            {
                console.error('Error fetching user data:', error);
                navigate('/');
            });
    };

    return (
        <div>
            {userData ? (
                <div>
                    <h1>Welcome, {userData.username}!</h1>
                    <p>Your Discord ID is: {userData.id}</p>
                    <p>You are a regional manager 😎</p>
                    <Button onClick={() => navigate('/chatlogs')}>Chat logs</Button>
                    <Button onClick={() => navigate('/seeding')}>Seeding</Button>
                </div>
            ) : (
                <p>Loading user data...</p>
            )}
        </div>
    );
};

export default Dashboard;
