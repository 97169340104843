import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Button from './Components/Button';
import TextInput from './Components/TextInput';

const Seeding = () =>
{
    const [userData, setUserData] = useState(null);
    const [inputs, setInputs] = useState({
        pullSeedingSheetID: '',
        pullSeedingEventID: '',
        pushSeedingSheetID: '',
        pushSeedingPhaseID: '',
        // Add more states for additional inputs if necessary
    });
    const navigate = useNavigate(); // Hook for navigation

    React.useEffect(() =>
    {
        document.title = "Seeding"
    })

    useEffect(() =>
    {
        const accessToken = sessionStorage.getItem('accessToken');
        if (accessToken) {
            fetchUserData(accessToken);
        } else {
            // Redirect to login if there's no access token
            navigate('/');
        }
    }, [navigate]);
    const fetchUserData = (accessToken) =>
    {
        axios.get('https://discord.com/api/users/@me', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response =>
            {
                setUserData(response.data);
                const authorizedIds = process.env.REACT_APP_AUTHORIZED_IDS.split(',');
                // Check if the user's ID is in the authorized IDs array
                if (!authorizedIds.includes(response.data.id)) {
                    // Redirect to login page if IDs do not match
                    navigate('/');
                }
            })
            .catch(error =>
            {
                console.error('Error fetching user data:', error);
                navigate('/');
            });
    };

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr', // two columns
        gridTemplateRows: '1fr 1fr', // two rows
        gap: '20px', // space between grid items
        padding: '20px' // padding around the grid
    };

    const itemStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // centers items vertically in the flex container
        justifyContent: 'space-around' // distributes space around items along the vertical line
    };

    const inputContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between', // distributes space between TextInput components
        width: '100%' // ensures TextInput components span the full width of their container
    };
    const handleChange = (inputId, value) =>
    {
        setInputs(prevInputs => ({
            ...prevInputs,
            [inputId]: value
        }));
    };

    const submitPullSeeding = async () =>
    {
        try {
            console.log(`${inputs.pullSeedingSheetID} ${inputs.pullSeedingEventID}`)
            const response = await fetch('http://rlcs.jaydenbutler.com:3001/api/pullSeeding', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sheetId: inputs.pullSeedingSheetID,
                    eventId: inputs.pullSeedingEventID
                })
            });
            if (response.ok) {
                alert("Seeding pulled successfully!")
            } else {
                console.error(response)
                alert("Error doing seeding, check console for more info")
            }
        } catch (error) {
            console.error(error)
            alert("Error doing seeding, check console for more info")
        }
    }

    const submitPushSeeding = async () =>
        {
            try {
                console.log(`${inputs.pushSeedingSheetID} ${inputs.pushSeedingPhaseID}`)
                const response = await fetch('http://rlcs.jaydenbutler.com:3001/api/pushSeeding', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        sheetId: inputs.pushSeedingSheetID,
                        phaseId: inputs.pushSeedingPhaseID
                    })
                });
                if (response.ok) {
                    alert("Seeding pushed successfully!")
                } else {
                    console.error(response)
                    alert("Error pushing seeding, check console for more info")
                }
            } catch (error) {
                console.error(error)
                alert("Error pushing seeding, check console for more info")
            }
        }

    return (
        <div>
            {userData ? (
                <div>
                    <h1>Welcome, {userData.username}!</h1>
                    <p>Seeding shit please kill me now</p>
                    <div style={gridStyle}>
                        <div style={itemStyle}>
                            <h2>Pull Seeding</h2>
                            <div style={inputContainerStyle}>
                                <TextInput
                                    placeholder="Sheet ID"
                                    value={inputs.pullSeedingSheetID}
                                    onChange={(e) => { handleChange('pullSeedingSheetID', e.target.value) }}
                                />
                                <TextInput
                                    placeholder="Event ID"
                                    value={inputs.pullSeedingEventID}
                                    onChange={(e) => { handleChange('pullSeedingEventID', e.target.value) }}
                                />
                            </div>
                            <Button onClick={submitPullSeeding}>Submit</Button>
                        </div>
                        <div style={itemStyle}>
                            <h2>Do Prizing (jayden only)</h2>
                            <div style={inputContainerStyle}>
                                <TextInput placeholder="Input 1" />
                                <TextInput placeholder="Input 2" />
                            </div>
                            <Button>Submit</Button>
                        </div>
                        <div style={itemStyle}>
                            <h2>Push Seeding</h2>
                            <div style={inputContainerStyle}>
                                <TextInput
                                    placeholder="Sheet ID"
                                    value={inputs.pushSeedingSheetID}
                                    onChange={(e) => { handleChange('pushSeedingSheetID', e.target.value) }}
                                />
                                <TextInput
                                    placeholder="Phase ID"
                                    value={inputs.pushSeedingPhaseID}
                                    onChange={(e) => { handleChange('pushSeedingPhaseID', e.target.value) }}
                                />
                            </div>
                            <Button onClick={submitPushSeeding}>Submit</Button>
                        </div>
                        <div style={itemStyle}>
                            <h2>TBD</h2>
                            <div style={inputContainerStyle}>
                                <TextInput placeholder="Input 1" />
                                <TextInput placeholder="Input 2" />
                            </div>
                            <Button>Submit</Button>
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => { navigate('/dashboard') }}>Back frfr</Button>
                    </div>
                </div>
            ) : (
                <p>Loading user data...</p>
            )
            }
        </div >
    );
};

export default Seeding;
