import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Button from './Components/Button';

const ChatLogs = () =>
{
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate(); // Hook for navigation

    React.useEffect(() => {
        document.title = "Chat Logs"
    })

    useEffect(() =>
    {
        const accessToken = sessionStorage.getItem('accessToken');
        if (accessToken) {
            fetchUserData(accessToken);
        } else {
            // Redirect to login if there's no access token
            navigate('/');
        }
    }, [navigate]);
    const fetchUserData = (accessToken) =>
    {
        axios.get('https://discord.com/api/users/@me', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response =>
            {
                setUserData(response.data);
                const authorizedIds = process.env.REACT_APP_AUTHORIZED_IDS.split(',');
                // Check if the user's ID is in the authorized IDs array
                if (!authorizedIds.includes(response.data.id)) {
                    // Redirect to login page if IDs do not match
                    navigate('/');
                }
            })
            .catch(error =>
            {
                console.error('Error fetching user data:', error);
                navigate('/');
            });
    };

    // Files stuff
    const [files, setFiles] = useState({});
    const [currentPath, setCurrentPath] = useState([]);
    const [fileContent, setFileContent] = useState('');
    const [refresh, setRefresh] = useState(false);

    const forceUpdate = () => setRefresh(prev => !prev);
    
        
    useEffect(() => {
        // Fetch the root directory initially or any directory based on currentPath
        fetchFiles(currentPath.join('/'));
    }, [currentPath]);  // Dependency array includes currentPath to refetch on change

    const fetchFiles = async (path = '') => {
        try {
            const response = await axios.get(`http://rlcs.jaydenbutler.com:3001/api/files?path=${encodeURIComponent(path)}`);
            setFiles(response.data); // Update with the fetched file structure
        } catch (error) {
            console.error('Failed to fetch files:', error);
        }
    };

    const fetchContent = async (filePath) => {
        try {
            const response = await axios.get(`http://rlcs.jaydenbutler.com:3001/api/content`, { params: { path: filePath.join('/') } });
            setFileContent(response.data);
        } catch (error) {
            console.error('Failed to fetch file content:', error);
        }
    };

    const handleFileClick = (fileName) => {
        fetchContent([...currentPath, fileName]);
    };

    const handleFolderClick = (folderName) => {
        setCurrentPath([...currentPath, folderName]);  // Update path state to navigate into the folder
    };

    const renderFileSystem = (files) => {
        return Object.entries(files).map(([key, value]) => {
            if (value === 'file') {
                return <li key={key} onClick={() => handleFileClick(key)}>{key}</li>;
            } else {
                return <li key={key} onClick={() => handleFolderClick(key)}>{key}</li>;
            }
        });
    };

    const handleBack = () => {
        if (currentPath.length > 0) {
            console.log(currentPath)
            const newPath = currentPath.slice(0, -1); // Remove the last element of the path
            setCurrentPath(newPath);
            fetchFiles(newPath.join('/'));
            setFileContent(''); // Clear any displayed file content
        }
    };

    return (
        <div>
            {userData ? (
                <div>
                    <h1>Welcome, {userData.username}!</h1>
                    <p>Chat log viewer</p>
                    <h1>Chat Logs</h1>
                    <button onClick={handleBack} disabled={currentPath.length === 0}>Back</button>
                    {fileContent ? (
                        <pre>{fileContent}</pre>
                    ) : (
                        <ul>{renderFileSystem(files)}</ul>
                    )}

                </div>
            ) : (
                <p>Loading user data...</p>
            )}
            <div>
                <Button onClick={() => {navigate('/dashboard')}}>Back frfr</Button>
            </div>
        </div>
    );
};

export default ChatLogs;
