import React from 'react';

const styles = {
  button: {
    backgroundColor: '#007BFF', // Light blue color
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    outline: 'none',
    boxShadow: '2px 2px 5px rgba(0,0,0,0.2)', // Shadow for raised effect
    transition: 'box-shadow 0.3s ease-in-out',
  }
};

const Button = ({ onClick, children }) => {
  return (
    <button
      style={styles.button}
      onClick={onClick}
      onMouseDown={() => {
        const newStyle = { ...styles.button, boxShadow: 'inset 1px 1px 2px rgba(0,0,0,0.3)' };
        styles.button = newStyle;
      }}
      onMouseUp={() => {
        const originalStyle = {
          ...styles.button,
          boxShadow: '2px 2px 5px rgba(0,0,0,0.2)'
        };
        styles.button = originalStyle;
      }}
      onMouseOut={() => {
        const originalStyle = {
          ...styles.button,
          boxShadow: '2px 2px 5px rgba(0,0,0,0.2)'
        };
        styles.button = originalStyle;
      }}
    >
      {children}
    </button>
  );
};

export default Button;