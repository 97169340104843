const TextInput = ({ value, onChange, placeholder }) =>
{
    const style = {
        padding: '8px 12px',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        width: '100%', // This makes the input stretch to its container
        boxSizing: 'border-box' // This makes padding not affect the total width
    };

    return (
        <input
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            style={style}
        />
    );
};

export default TextInput;