import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './Dashboard';
import ChatLogs from './ChatLogs';
import Seeding from './Seeding';

const clientId = process.env.REACT_APP_CLIENT_ID;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;

export function App()
{
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/auth" element={<AuthHandler />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/chatlogs" element={<ChatLogs />} />
                <Route path="/seeding" element={<Seeding />} />
            </Routes>
        </Router>
    );
}

function Home()
{
    const navigate = useNavigate();
    React.useEffect(() =>
    {
        document.title = "Login"
    })
    React.useEffect(() =>
    {
        const accessToken = sessionStorage.getItem('accessToken');
        if (accessToken) {
            axios.get('https://discord.com/api/users/@me', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then(response =>
            {
                const authorizedIds = process.env.REACT_APP_AUTHORIZED_IDS.split(',');
                // Check if the user's ID is in the authorized IDs array
                if (authorizedIds.includes(response.data.id)) {
                    // Redirect to login page if IDs do match
                    navigate('/dashboard');
                }
            }).catch(error =>
            {
                console.error('Error fetching user data:', error);
                navigate('/');
            });
        }
    }, [navigate]);
    const loginUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=identify%20email`;

    const handleLogin = () =>
    {
        window.location.href = loginUrl;
    };

    return (
        <div>
            <h1>Welcome to the RLCS Regional Manager dashboard</h1>
            <button onClick={handleLogin}>Login with Discord</button>
        </div>
    );
}

function AuthHandler()
{
    const navigate = useNavigate();
    const code = new URLSearchParams(window.location.search).get('code');

    React.useEffect(() =>
    {
        document.title = "Login"
    })

    React.useEffect(() =>
    {
        if (code) {
            axios.post('https://discord.com/api/oauth2/token', new URLSearchParams({
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET,
                code,
                grant_type: 'authorization_code',
                redirect_uri: process.env.REACT_APP_REDIRECT_URI,
                scope: 'identify email'
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(response =>
                {
                    sessionStorage.setItem('accessToken', response.data.access_token);
                    console.log(response)
                    navigate('/dashboard');
                })
                .catch(error => console.error('Auth error', error));
        }
    }, [code, navigate]);

    return <div>Authenticating...</div>;
}
export default App